import React, { useEffect, useState } from 'react';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import BEMHelper from 'react-bem-helper';

import { Container, Row, Col, Alert,  } from 'reactstrap';
import qs from 'qs';
import { format, toDate } from 'date-fns';

// Elements
import { Button } from 'bokamera-embedded-ui';
import Panel from '@/components/elements/Panel';
import Spinner from '@/components/elements/Spinner';
import { CompanyType, BookingType, Configuration, CheckoutStateType } from '@/types';
import * as actions from '@/actions';
import { Trans } from '@lingui/macro';
import Booking from '@/components/elements/Booking';
import api, { CreateCheckoutPartial } from '@/api/services';
import { getCountryCode, scrollToElement } from '@/misc/common';

import './Completed.css';
import { ApplicationState } from '@/store';
import readConfigurationProperty from '@/misc/readConfigurationProperty';

interface Props {
    company: CompanyType;
    dispatch: Dispatch<any>;
    configuration: Configuration;
    checkout: CheckoutStateType;
}

const c = new BEMHelper({ name: 'Completed' });

export function PaymentFailed({ company, configuration, checkout, dispatch }: Props) {
    const [booking, setBooking] = useState<BookingType>();
    const [isPayClicked, setIsPayClicked] = useState(false);

    const paymentEnabled = booking?.Service?.IsPaymentEnabled;

    const payWithPaysonV1 = company?.BookingSettings?.PaymentProviderId === 1;
    const payWithPaysonV2 = company?.BookingSettings?.PaymentProviderId === 2;
    const payWithBillmate = company?.BookingSettings?.PaymentProviderId === 3;

    const statusRequiresPayment = booking?.StatusId === 5 || booking?.StatusId === 6;

    const needToPay = paymentEnabled && statusRequiresPayment;
    
    const { cancellationCode, bookingId } = qs.parse(document.location.search, {
        ignoreQueryPrefix: true,
    });
    
    useEffect(() => {
        dispatch(actions.fetchCompanyAsync.request());
        if(bookingId && cancellationCode) {
          api
            .getBooking({
              Id: bookingId,
              CancellationCode: cancellationCode,
              IncludeCustomerInformation: true,
            })
            .then((response) => {
              setBooking(response.Results[0]);
            })
            .catch((err) => console.log(err));
        }
    }, [bookingId, cancellationCode]);

    useEffect(() => {
        if (!!checkout.data) {
            const intervalId = setInterval(() => {
                const paysonContainer = document.getElementById('paysonContainer');
                if (paysonContainer) {
                    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
                        scrollToElement({ company });
                    }
                    const scriptParentNode: Node | null = paysonContainer.parentNode;
                    if (!!scriptParentNode) {
                        // How to rewrite it in correct way?
                        // @ts-ignore
                        const scriptNode = scriptParentNode.getElementsByTagName('script')[0];
                        const scriptNodeWithContent = document.createElement('script');
                        scriptNodeWithContent.src = scriptNode.src;
                        document.head.appendChild(scriptNodeWithContent);
                        clearInterval(intervalId);
                    }
                }
            }, 500);
        }
    }, [checkout.data]);

    if (!booking || !company) return null;

    if (checkout.hasError) {
        return (
            <Panel>
                <Row>
                    <Col xs={12}>
                        <Trans id="completed.error" />
                    </Col>
                </Row>
                {checkout?.error?.ResponseStatus?.Message ? (
                    <Row>
                        <Col xs={12}>{checkout.error.ResponseStatus?.Message}</Col>
                    </Row>
                ) : null}
            </Panel>
        );
    }

    if (!company) {
        return (
            <Container fluid>
                <Spinner noGraceTime noColor />
            </Container>
        );
    }
    const targetOrigin = configuration?.targetOrigin
      ? configuration?.targetOrigin
      : configuration?._targetOrigin; 

    const onCancel = targetOrigin ?  () => {
      // @ts-ignore
      window.location.replace(targetOrigin);
    }: undefined;

    // Condition 1 - not clicked
    const heading = isPayClicked ? <Trans id="pay" /> : <Trans id="paymentFailed" />


    return (
      <Container className="p-3">
        <h2 className="text-center mb-3">
          {heading}
        </h2>
        {needToPay && payWithPaysonV1 && checkout.data ? (
          <Alert color="warning" className="clearfix">
            <div>
              {booking.PaymentExpiration && (
                <p>
                  <Trans id="bookingHasFee"></Trans>{" "}
                  <strong>
                    {format(
                      toDate(new Date(booking.PaymentExpiration)),
                      "d MMM, p"
                    )}
                  </strong>
                </p>
              )}
              {!booking.PaymentExpiration && (
                <p>
                  <Trans id="bookingHasFee1"></Trans>
                </p>
              )}
              <p>
                <Trans id="instructionsHasBeenSentToEmail"></Trans>
              </p>
            </div>
            <a
              className="float-right hidden-print"
              href={checkout?.data?.Snippet}
              target="_blank"
            >
              <Button>
                <Trans id="payNow"></Trans>
              </Button>
            </a>
          </Alert>
        ) : null}
        {checkout.isLoading && (
          <div className="hidden-print">
            <Spinner {...c("paymentLoading")}>
              <Trans id="yourBookingMustBePaid"></Trans>
            </Spinner>
          </div>
        )}
        {checkout.data && payWithPaysonV2 && (
          <Row className="hidden-print" style={{ marginBottom: "1rem" }}>
            <Col xs={12}>
              <div
                dangerouslySetInnerHTML={{ __html: checkout.data.Snippet }}
              />
            </Col>
          </Row>
        )}
        {checkout.data && payWithBillmate && (
          <iframe
            src={checkout.data.Url}
            data-testid="billmateContainer"
            width="100%"
            height="810px"
            frameBorder="0"
          ></iframe>
        )}

        {!checkout.data && booking && configuration ? (
          <Booking
            company={company}
            booking={booking}
            onPay={() => {

              setIsPayClicked(true);

              if(company.Id && company?.BookingSettings?.PaymentProviderId) {
                const payload: CreateCheckoutPartial = {
                  CompanyId: company.Id,
                  Id: booking.Id,
                  PaymentProviderId: company.BookingSettings.PaymentProviderId,
                  CountryCode: getCountryCode(readConfigurationProperty('language', 'se')),
                  Articles: [
                    {
                      ArticleTypeId: 1,
                      ArticleId: 0,
                      Quantity: 1
                    }
                  ],
                  customerEmail: booking.Customer.Email
                };
  
                dispatch({
                  type: "CREATE_CHECKOUT_REQUEST",
                  payload,
                });

              }

            }}
            onCancel={onCancel}
          />
        ) : null}
      </Container>
    );
}


export default compose<React.ComponentType>(
    connect(({ company, configuration, checkout}: ApplicationState) => ({
        company: company.data,
        configuration: configuration.data,
        checkout
    }))
)(PaymentFailed);
