import React from "react";
import { flatten } from "lodash";
import { ServiceType, CompanyType } from "@/types";
import { isBefore } from "@/misc/datetime";

const ServiceStructuredData: React.FC<{
  service: ServiceType;
  company: CompanyType;
}> = ({ service, company }) => {
  {
    const hoursAvailable = flatten([
      ...service.Schedules.RecurringSchedules.filter(
        (s) => s.Active && isBefore(new Date(), new Date(s.ValidTo))
      ).map((s) => ({
        opens: s.StartTime,
        closes: s.EndTime,
        dayOfWeek: s.RecurringScheduleDayOfWeekRelation.map(
          (r: any) => r.DayOfWeek
        ),
      })),
      ...service.Schedules.DateSchedules.reduce((acc: any[], curr) => {
        // @ts-ignore
        curr.Dates.filter((d: any) => d.IsActive).forEach((d: any) => {
          acc.push({
            opens: new Date(d.From).toISOString().split("T")[1],
            closes: new Date(d.To).toISOString().split("T")[1],
            validFrom: d.From,
            validThrough: d.To,
          });
        });
        return acc;
      }, []),
    ]);

    return (
      <script type="application/ld+json" key={service.Id}>
        {JSON.stringify({
          "@context": "https://schema.org/",
          "@type": "Service",
          provider: {
            "@type": "LocalBusiness",
            name: company.Name,
          },
          image: service.ImageUrl,
          description: service.Description,
          offers: service.Prices.map((price) => ({
            price: price.Price,
            priceCurrency: price.CurrencyId,
          })),
          ...(hoursAvailable.length > 0 ? { hoursAvailable } : {}),
        })}
      </script>
    );
  }
};

export default ServiceStructuredData;
