import { useAppSelector } from "@/hooks";
import { ConfigKeys } from "@/misc/constants";
import { getArticlesToShow } from "@/selectors";
import { useGetArticlesQuery } from "@/services/bmApi";
import React, { useRef } from "react";
import Article from "./Article";
import { RowsGrouped } from "./RowsGrouped";

const Articles = () => {
  const configuration = useAppSelector((state) => state.configuration.data);
  const layoutType = configuration[ConfigKeys.LISTING_LAYOUT];
  const ref = useRef(null);

  const { data: allArticles } = useGetArticlesQuery({
    CompanyId: configuration.company,
    // @ts-ignore
    Active: true,
  });

  const articles = allArticles?.Results
    ? getArticlesToShow(allArticles?.Results, configuration.articleIdsToShow).map(a => ({...a, Group: 'Value Cards'}))
    : [];

  return (
    <>
      {articles
        // TODO: show standalone type (2) and service type (1)
        // when the functionality is 100% there
        ?.filter((article) => article.ArticleTypeId !== 1)
        ?.filter((article) => article.ArticleTypeId !== 2)
        ?.map((article) => (
          <Article
            article={article}
            // @ts-ignore
            currency={article.PriceSign}
          />
        ))}
    </>
  );
};

export default Articles;
