import {
  format,
  formatDistanceStrict,
  toDate,
  isSameDay,
  subDays,
  subHours,
  subMinutes,
  addMinutes,
  addHours,
  addDays,
  isWithinInterval,
  FormatDistanceStrictOptions,
  parseSeconds,
} from "./datetime";
import qs from "qs";
import {
  ServiceType,
  TimeType,
  Configuration,
  BookingType,
  CompanyType,
  LanguageCode,
  CountryCode,
  PriceTimeUnit,
  Article,
  StripeUiMode,
} from "../types";
import { ConfigKeys } from "../misc/constants";
import Keycloak, { KeycloakProfile } from "keycloak-js";
import readConfigurationProperty from "./readConfigurationProperty";
import { pick } from "lodash";
import { keycloakConfig } from "@/keycloak";

interface ScrollToElementOptions {
  scrollOptions?: ScrollIntoViewOptions;

  // This needs to be set if the parent page needs to do the scrolling
  targetOrigin?: string;

  // This needs to be set if you want to read the settings from the postMessageOrigin
  // config from the company response
  company?: CompanyType;

  // If set it will scroll to this element, otherwise it will #bokamera-embedded element
  element?: HTMLElement | null;

  scrollAfterStep?: number;
}

export function readStyleProperty(property: string): any {
  if (!(window as any).BOKAMERA || !(window as any).BOKAMERA.style) {
    console.log("No external style configuration found for BOKAMERA");
    return null;
  }
  const setting = (window as any).BOKAMERA.style[property];

  if (!setting)
    console.log(
      `BOKAMERA: Unable to find style setting for property: ${property}`
    );

  return setting;
}

export function capitalize(string: string): string {
  if (!string || string.length === 0) return string;

  return string.charAt(0).toUpperCase() + string.slice(1);
}





interface formatMinutesToWordsOptions {
  abbreaviated?: boolean;
}
export function formatMinutesToWords(
  lengthInMinutes: number,
  options?: formatMinutesToWordsOptions & FormatDistanceStrictOptions
): string {
  const formattedMinutes = parseSeconds(lengthInMinutes * 60);
  const { days, minutes, hours } = formattedMinutes;
  let lengthString = "";
  const now = new Date();

  if (formattedMinutes && !options?.unit) {
    if (days) {
      lengthString = formatDistanceStrict(now, addDays(now, days), {
        ...options,
      });
    }
    if (hours) {
      // @ts-ignore
      const hoursString = formatDistanceStrict(now, addHours(now, hours), {
        ...options,
      });
      lengthString = `${lengthString} ${hoursString}`;
    }
    if (minutes) {
      // @ts-ignore
      const minutesString = formatDistanceStrict(
        now,
        addMinutes(now, minutes),
        {
          ...options,
        }
      );
      // @ts-ignore
      lengthString = `${lengthString} ${minutesString}`;
    }

    if (!days && !minutes && !hours) {
      lengthString = formatDistanceStrict(now, now, {
        unit: options?.unit,
      });
    }

    if (options?.abbreaviated) {
      return lengthString
        .trim()
        .split(" ")
        .reduce((acc, curr) => {
          if (!isNumeric(curr)) {
            acc = `${acc}${curr.slice(0, 1)}`;
          } else {
            acc = `${acc} ${curr}`;
          }

          return acc;
        }, "")
        .replace(/\s/g, "");
    }
  } else if (options?.unit) {

    lengthString = formatDistanceStrict(now, addMinutes(now, lengthInMinutes), {
      ...options,
    });
    
    const { minutes, hours }  = parseSeconds(lengthInMinutes * 60);
    let decimalPart = '';
    let wholePart = parseInt(lengthString.trim().split(' ')[0]);
    if(options.unit === 'day') {
      decimalPart = (hours / 24) ? (hours / 24).toPrecision(1) : '';
    } else if(options.unit === 'hour') {
      decimalPart = (minutes / 60) ? (minutes / 60).toPrecision(1) : '';
    }

    if (options?.abbreaviated) {
      return `${wholePart}${decimalPart}`;
    }
    let tmp = lengthString.split(" ");
    tmp.splice(1, 0, decimalPart);
    tmp = tmp.reduce((acc, curr, index) => {
      if(!isNaN(parseFloat(curr)) && index === 0) {
          acc.push(parseFloat(curr))
      } else if (!isNaN(parseFloat(curr)) && !isNaN(parseFloat(acc[index - 1])) && index > 0) {
          acc[index - 1] = acc[index - 1] + parseFloat(curr);
      } else if(curr) {
          acc.push(curr);
      }
      return acc;
    }, [] as any[]);

    return tmp.join(" ");
  }

  return lengthString.trim();
}
type ConvertOptions = "minutes" | "hours" | "days";
// TODO: pluck from FormatDistanceStrictOptions
type ConvertReturnType =
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "month"
  | "year"
  | undefined;

export function convertToFormatOptions(
  unit: ConvertOptions
): ConvertReturnType {
  switch (unit) {
    case "minutes":
      return "minute";
    case "hours":
      return "hour";
    case "days":
      return "day";
    default:
      break;
  }
}

interface FormatFromToConfig {
  renderEndTimeIfSameDay?: boolean;
  renderDateIfSameDay?: boolean;
}

export function formatFromTo(
  from: string,
  to: string,
  {
    renderEndTimeIfSameDay = false,
    renderDateIfSameDay = false,
  }: FormatFromToConfig
): string {
  const fromDateValue = toDate(from);
  const toDateValue = toDate(to);
  let str;

  if (isSameDay(from, to)) {
    if (renderEndTimeIfSameDay) {
      str = `${format(fromDateValue, "p")} - ${format(toDateValue, "p")}`;
    } else {
      str = `${format(fromDateValue, "p")}`;
    }
    if (renderDateIfSameDay) {
      str = `${format(fromDateValue, "d MMMM")}, ${str}`;
    }
  } else if (
    isWithinInterval(toDateValue, {
      start: fromDateValue,
      end: addHours(fromDateValue, 23),
    })
  ) {
    str = `${format(fromDateValue, "p")} - ${format(toDateValue, "p")}`;
  } else {
    str = `${format(fromDateValue, "d MMMM, p")} - ${format(
      toDateValue,
      "d MMMM, p"
    )}`;
  }

  return str;
}

function isNumeric(str: string | number) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    // @ts-ignore
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function calculateLastTimeToUnbook(
  service: ServiceType,
  time: TimeType
): Date {
  let lastTimeToUnbookDate = toDate(time.From);

  lastTimeToUnbookDate = subDays(
    lastTimeToUnbookDate,
    service.UnbookBeforeDays || 0
  );
  lastTimeToUnbookDate = subHours(
    lastTimeToUnbookDate,
    service.UnbookBeforeHours || 0
  );
  lastTimeToUnbookDate = subMinutes(
    lastTimeToUnbookDate,
    service.UnbookBeforeMinutes || 0
  );

  return lastTimeToUnbookDate;
}

export function groupArray(
  array: Array<{ [name: string]: any }>,
  sectioningAttributeName: string
): {} {
  if (!(array instanceof Array)) {
    console.error("Did not supply an array to convertion method. Aborting.");

    return {};
  }

  let map: any = {};

  array.forEach((item) => {
    var sectionTitle = item[sectioningAttributeName] || "";
    var section = map[sectionTitle];

    if (section) {
      section.push(item);
    } else {
      map[sectionTitle] = [item];
    }
  });

  return map;
}

export function scrollToTop(smooth: boolean = true) {
  const element = window?.bookingAppContainer;
  const behavior = smooth ? "smooth" : "auto";

  if (element) {
    element.scrollIntoView({ behavior });
  }
}

export function sortByDashes(arr: string[]) {
  if (!arr) {
    return [];
  }

  return [...arr].sort((a, b) => {
    if (!a.match(/-/g) && !b.match(/-/g)) {
      if (a > b) {
        return 1;
      } else if (b > a) {
        return -1;
      }
    } else if (a.match(/-/g) && !b.match(/-/g)) {
      return 1;
    } else if (a && b && b.includes(a)) {
      return -1;
    } else if (a && !a.match(/-/g) && b.match(/-/g) && b.includes(a)) {
      return 1;
    } else if (!a.match(/-/g) && b.match(/-/g)) {
      return -1;
    } else if (a.match(/-/g) && b.match(/-/g)) {
      // @ts-ignore
      return a.match(/-/g).length - b.match(/-/g).length;
    }
    return 0;
  });
}

export const realmParamsMapping = {
  bookmore: "BookMore",
  ["bookmore-admin"]: "BookMoreAdmin",
};

export const createArticlePaymentUrls = (
  configuration: Configuration,
  uiMode: StripeUiMode,
  articleId: number,
  customerEmail: string,
  cancellationCode?: string
) => {
  if (uiMode === "hosted") {
    return createHostedArticlePaymentUrls(configuration, articleId, customerEmail);
  } else {
    return createEmbeddedArticlePaymentUrls(articleId, customerEmail);
  }
  
};

const createHostedArticlePaymentUrls = (
  configuration: Configuration,
  articleId: number,
  customerEmail: string
) => {
  const params = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  let confirmationUrl;
  
  const checkoutUrl = new URL(window.location.href);
  checkoutUrl.search = qs.stringify({
    ...params,
    email: customerEmail,
    articleId
  });

  checkoutUrl.hash = "#/payment-failed";

  if (configuration.paymentConfirmationURL) {
    confirmationUrl = new URL(configuration.paymentConfirmationURL);
    confirmationUrl.search = qs.stringify({
      articleId
    });
  } else {
    confirmationUrl = new URL(`${window.location.origin}?${qs.stringify(
      {
        ...params,
        articleId,
        email: customerEmail
      },
      {}
    )}#/article-payment-success`)
  }

  return {
    confirmationUrl,
    checkoutUrl,
  };
}

export const createEmbeddedArticlePaymentUrls = (
  articleId: number,
  customerEmail: string
) => {
  const params = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  let confirmationUrl;
  
  const checkoutUrl = new URL(window.location.href);
  checkoutUrl.search = qs.stringify({
    ...params,
    email: customerEmail,
    articleId
  });
  checkoutUrl.hash = "#/payment-failed";

  confirmationUrl = new URL(`${window.location.origin}?${qs.stringify(
    {
      ...params,
      articleId,
      email: customerEmail
    },
    {}
  )}#/article-payment-success`);

  return {
    confirmationUrl,
    checkoutUrl,
  };
}

export const createPaymentUrls = (
  configuration: Configuration,
  uiMode: StripeUiMode = 'embedded',
  bookingId: number,
  customerEmail: string,
  cancellationCode?: string
) => {
  if (uiMode === "hosted") {
    return createHostedPaymentUrls(configuration, bookingId, customerEmail, cancellationCode);
  } else {
    return createEmbeddedPaymentUrls(configuration, bookingId, customerEmail, cancellationCode);
  }
};

const createHostedPaymentUrls = (
  configuration: Configuration,
  bookingId: number,
  customerEmail: string,
  cancellationCode?: string,
  failPathname = '/payment-failed',
  successPathname = '/payment-success'
) => {
  const params = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  let confirmationUrl;
  
  const checkoutUrl = new URL(window.location.href);
  checkoutUrl.search = qs.stringify({
    ...params,
    bookingId,
    email: customerEmail,
    ...(cancellationCode ? { cancellationCode: cancellationCode} : {}),
  });
  checkoutUrl.hash = "#/payment-failed";

  if (configuration.paymentConfirmationURL) {
    confirmationUrl = new URL(configuration.paymentConfirmationURL);
    confirmationUrl.search = qs.stringify({
      bookingId,
      email: customerEmail,
      ...(cancellationCode ? { cancellationCode: cancellationCode} : {}),
    });
  } else {
    confirmationUrl = new URL(`${window.location.origin}?${qs.stringify(
      {
        ...params,
        bookingId,
        email: customerEmail,
        ...(cancellationCode ? { cancellationCode: cancellationCode} : {})
      },
      {}
    )}#/payment-success`)
  }

  return {
    confirmationUrl,
    checkoutUrl,
  };
};

const createEmbeddedPaymentUrls = (
  configuration: Configuration,
  bookingId: number,
  customerEmail: string,
  cancellationCode?: string,
) => {
  const params = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });


  const confirmationUrl = new URL(`${window.location.origin}?${qs.stringify(
    {
      ...params,
      bookingId,
      email: customerEmail,
      ...(cancellationCode ? { cancellationCode: cancellationCode} : {}),
    },
    {}
  )}#/payment-success`)


  const checkoutUrl = new URL(window.location.href);
  checkoutUrl.search = qs.stringify({
    ...params,
    bookingId,
    email: customerEmail,
    ...(cancellationCode ? { cancellationCode: cancellationCode} : {}),
  });
  checkoutUrl.hash = "#/payment-failed";

  return {
    confirmationUrl,
    checkoutUrl,
  };
}

interface ScrollToElementOptions {
  scrollOptions?: ScrollIntoViewOptions;

  // This needs to be set if the parent page needs to do the scrolling
  targetOrigin?: string;

  // This needs to be set if you want to read the settings from the postMessageOrigin
  // config from the company response
  company?: CompanyType;

  // If set it will scroll to this element, otherwise it will #bokamera-embedded element
  element?: HTMLElement | null;

  scrollOffsetY?: number;

  /**
   * After this step the scroll will work
   */
  scrollAfterStep?: number;

  /**
   * When we pass this it won't count the scroll,
   * and it will scroll without checking any logic
   */
  initiatedByUser?: boolean;

  preventAutoscroll?: boolean;
}

let scrollCount = 0;
export function scrollToElement({
  scrollOptions = { behavior: "smooth" },
  element,
  scrollOffsetY = Number(readConfigurationProperty("topOffset", 0)),
  scrollAfterStep = Number(readConfigurationProperty("scrollAfterStep", 0)),
  targetOrigin = readConfigurationProperty("targetOrigin")
    ? readConfigurationProperty("targetOrigin")
    : readConfigurationProperty("_targetOrigin"),
  preventAutoscroll = readConfigurationProperty('preventAutoscroll', false),
  initiatedByUser = false,
}: ScrollToElementOptions = {}) {
  try {
    if (!initiatedByUser) {
      scrollCount++;
    }

    if (preventAutoscroll || (!initiatedByUser && scrollCount <= scrollAfterStep)) {
      return;
    }

    if (window?.bookingAppContainer) {
      const _element = element || (window?.bookingAppContainer as HTMLElement);
      _element.scrollIntoView(scrollOptions);
      return;
    }

    const _element =
      element || (document.getElementById("bokamera-embedded") as HTMLElement);

    const behavior = scrollOptions?.behavior ? scrollOptions?.behavior : "auto";

    const scrollingElement = document.scrollingElement;

    const topTo = _element?.getBoundingClientRect().top + scrollOffsetY;

    if (/iPhone|Safari/i.test(navigator.userAgent)) {
      scrollOptions["block"] = "start";
      delete scrollOptions["behavior"];
    }

    if (typeof topTo === "number") {
      const scrollToPayload = {
        top: Math.round(topTo),
        left: 0,
        behavior,
      };
      // debugger;
      if (
        /iPhone|Safari/i.test(navigator.userAgent) &&
        scrollingElement &&
        window.parentIFrame
      ) {
        const top = _element.getBoundingClientRect().top - scrollOffsetY;
        window.parent.postMessage(
          {
            scrollTo: {
              top,
              left: 0,
              elementSelector: "#bokamera-embedded",
              initiatedByUser,
            },
          },
          targetOrigin || "*"
        );
      } else if (/iPhone|Safari/i.test(navigator.userAgent)) {
        const top = _element.getBoundingClientRect().top - scrollOffsetY;
        window.scrollTo(0, top);
      } else if (
        window.parentIFrame &&
        /Firefox/i.test(navigator.userAgent) &&
        !/Seamonkey/i.test(navigator.userAgent)
      ) {
        const top = _element.getBoundingClientRect().top - scrollOffsetY;
        window.parent.postMessage(
          {
            scrollTo: {
              top,
              left: 0,
              elementSelector: "#bokamera-embedded",
              initiatedByUser,
            },
          },
          targetOrigin || "*"
        );
      } else if (scrollingElement) {
        _element.scrollIntoView(scrollOptions);
      }
    }
  } catch (error) {
    console.error(
      "Try removing the targetOrigin from your configuration.",
      error
    );
  }
}

export const getCountryCode = (language: LanguageCode): CountryCode => {
  if (language === "sv") {
    return "se";
  }

  return language;
};

export const getAuthData = (ui: KeycloakProfile, keycloak: Keycloak) => {
  return {
    UserId: ui.id,
    UserName: ui.username,
    DisplayName: ui.firstName,
    BearerToken: keycloak.token,
    RefreshToken: keycloak.refreshToken,
    ProfileUrl: keycloak.createAccountUrl(),
  };
};

export const getTranslate = (localizeState: { [key in string]: string }) => {
  return (key: string) => localizeState[key];
};

export const tryParseJSONObject = (jsonString: string) => {
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {}

  return false;
};

export async function clearSession(clientId: string, refreshToken: string) {
  var myHeaders = new Headers();
  var urlencoded = new URLSearchParams();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  urlencoded.append("refresh_token", refreshToken);
  urlencoded.append("client_id", clientId);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
  };

  return fetch(keycloakConfig.logoutEndpoint, requestOptions);
}

export function isEmbed() {
  return window.location !== window.parent.location;
}

export function isDirect() {
  return window.location === window.parent.location;
}

export function transformConfiguration(
  parsedConfiguration: any
): Partial<Configuration> | undefined {
  if (!parsedConfiguration || Object.keys(parsedConfiguration).length === 0) {
    return;
  }

  return Object.keys(parsedConfiguration).reduce((acc, curr, index) => {
    const fieldValue = Object.values(parsedConfiguration)[index];
    if (typeof fieldValue === "string" && fieldValue.includes(",")) {
      acc[curr] = fieldValue.split(",");
    } else if (fieldValue === "true") {
      acc[curr] = true;
    } else if (fieldValue === "false") {
      acc[curr] = false;
    } else {
      acc[curr] = fieldValue;
    }

    return acc;
  }, {} as { [key in string]: any });
}

export function getConfiguration(): Configuration {
  let locationSearchParsed: any = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  let locationHashParsed: any = qs.parse(window.location.hash.split("?")[1], {
    ignoreQueryPrefix: true,
  });

  locationSearchParsed = transformConfiguration(locationSearchParsed);
  locationHashParsed = transformConfiguration(locationHashParsed);

  if (locationSearchParsed) {
    return locationSearchParsed;
  }

  return locationHashParsed;
}

export function ensureParentIFrameIsSet() {
  return new Promise(function (resolve, reject) {
    (function waitForFoo() {
      if (window?.parentIFrame && window.parentIFrame.getId()) {
        return resolve(window.parentIFrame.getId());
      }
      setTimeout(waitForFoo, 20);
    })();
  });
}

export const getUnitFromCalculationTypeId = (id: number) : PriceTimeUnit => {
  switch (id) {
      case 1:
          return 'service';
      case 2:
          return 'minute';
      case 3:
          return 'hour';
      case 4:
          return 'day';
      default:
          return 'service';
  }
}

/**
 * Returns natural unit, given the values in minutes
 * @param marks array of minutes
 */
export const getUnit = (marks: number[]) => {
  return marks.every((m) => m < 60)
    ? "minute"
    : marks.every((m) => m >= 60 && m < 1 * 60 * 24) ||
      (marks.some((m) => m >= 60 && m < 1 * 60 * 24) &&
        marks.some((m) => m >= 1 * 60 * 24) &&
        marks.every((m) => m >= 60))
    ? "hour"
    : marks.every((m) => m >= 1 * 60 * 24)
    ? "day"
    : "minute";
};

export const IS_PROD = process.env.REACT_APP_ENVIRONMENT === 'prod';
export const IS_DEV = process.env.REACT_APP_ENVIRONMENT === 'test';

export const hasArrayFieldChanged = (a: string[], b: string[]) => {
  if (typeof a !== 'undefined' && typeof b !== 'undefined' && a.toString() !== b.toString()) {
    return true;
  } else if (typeof a !== 'undefined' && typeof b === 'undefined') {
    return true;
  } else if (typeof b !== 'undefined' && typeof a === 'undefined') {
    return true;
  } else if (Array.isArray(a) && typeof b === 'undefined') {
    return true;
  } else if (Array.isArray(b) && typeof a === 'undefined') {
    return true;
  } else if(typeof a === 'string' && typeof b === 'undefined') {
    return true;
  } else if(typeof b === 'string' && typeof a === 'undefined') {
    return true;
  } else if(typeof a === 'string' && Array.isArray(b)) {
    return true;
  } else if(typeof b === 'string' && Array.isArray(a)) {
    return true;
  }
};

export function getBrowserFlags() {
  if(window && window.navigator) {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    
    return {
      iOSSafari
    }
  }

  return {
    iOSSafari: false
  };
}

export const getXlanguageCode = () => {
    if(readConfigurationProperty(ConfigKeys.LANGUAGE) === "no") {
      return 'nb-no';
    }

    return readConfigurationProperty(ConfigKeys.LANGUAGE) || "sv"
}