import { createArticlePaymentUrls, createPaymentUrls, getCountryCode } from "@/misc/common";
import {
  Configuration,
  PaysonV1CreateCheckout,
  PaysonV2CreateCheckout,
  QvicklyCreateCheckout,
  StripeUiMode,
} from "@/types";
import { AxiosRequestConfig } from "axios";
import { CreateCheckoutPartial } from "./services";

interface CreateCheckoutExtended extends CreateCheckoutPartial {
  customerEmail: string;
  InternalReferenceId?: string;
  cancellationCode?: string;
}

interface PaymentInterface {
  createCheckout(checkout: CreateCheckoutExtended): AxiosRequestConfig;
}

export class PaysonV1Payment implements PaymentInterface {
  private url = "/payment/payson/v1/checkout";
  private configuration: Configuration;

  constructor(configuration: Configuration) {
    this.configuration = configuration;
  }

  createCheckout(checkout: CreateCheckoutExtended): AxiosRequestConfig {
    const _createPaymentUrls =
      checkout.Articles.length > 0 && checkout.Articles[0].ArticleId
        ? createArticlePaymentUrls
        : createPaymentUrls;

    const { checkoutUrl, confirmationUrl } = _createPaymentUrls(
      this.configuration,
      'hosted',
      checkout.Id,
      checkout.customerEmail,
      checkout.cancellationCode
    );

    let payloadData: PaysonV1CreateCheckout = {
      Articles: checkout.Articles,
      BookingId: Number(checkout.Id),
      CompanyId: checkout.CompanyId,
      // @ts-ignore
      CheckoutUrl: checkoutUrl.href,
      // @ts-ignore
      ConfirmationUrl: confirmationUrl.href,
      Language: this.configuration.language || 'sv',
    };

    return {
      url: this.url,
      method: "POST",
      data: payloadData,
    };
  }
}

class PaysonV2Payment implements PaymentInterface {
  private url = "/payment/payson/v2/checkout";
  private configuration: Configuration;

  constructor(configuration: Configuration) {
    this.configuration = configuration;
  }

  createCheckout(checkout: CreateCheckoutExtended): AxiosRequestConfig {
    const _createPaymentUrls =
      checkout.Articles.length > 0 && checkout.Articles[0].ArticleId
        ? createArticlePaymentUrls
        : createPaymentUrls;

    const { checkoutUrl, confirmationUrl } = _createPaymentUrls(
      this.configuration,
      'hosted',
      checkout.Id,
      checkout.customerEmail,
      checkout.cancellationCode
    );

    let payloadData: PaysonV2CreateCheckout = {
      Articles: checkout.Articles,
      InternalReferenceId: String(checkout.Id),
      CompanyId: checkout.CompanyId,
      CheckoutUrl: checkoutUrl.href,
      ConfirmationUrl: confirmationUrl.href,
      CountryCode: checkout.CountryCode || "se",
      Language: this.configuration.language || 'sv',
    };

    return {
      url: this.url,
      method: "POST",
      data: payloadData,
    };
  }
}

class QvicklyPayment implements PaymentInterface {
  private url = "/payment/billmate/v1/checkout";
  private configuration: Configuration;

  constructor(configuration: Configuration) {
    this.configuration = configuration;
  }

  createCheckout(checkout: CreateCheckoutExtended): AxiosRequestConfig {
    const _createPaymentUrls =
      checkout.Articles.length > 0 && checkout.Articles[0].ArticleId
        ? createArticlePaymentUrls
        : createPaymentUrls;

    const { checkoutUrl, confirmationUrl } = _createPaymentUrls(
      this.configuration,
      'hosted',
      checkout.Id,
      checkout.customerEmail,
      checkout.cancellationCode
    );

    let payloadData: QvicklyCreateCheckout = {
      Articles: checkout.Articles,
      CompanyId: checkout.CompanyId,
      CheckoutUrl: checkoutUrl.href,
      ConfirmationUrl: confirmationUrl.href,
      InternalReferenceId: String(checkout.Id),
      Language: this.configuration.language || 'sv',
    };

    return {
      url: this.url,
      method: "POST",
      data: payloadData,
    };
  }
}

interface StripeCreateCheckout extends CreateCheckoutExtended {
  UiMode?: StripeUiMode;
  ConfirmationUrl?: string;
}
class StripePayment implements PaymentInterface {
  private url = "/payment/stripe/v1/checkout";
  private uiMode: StripeUiMode = "embedded";
  private configuration: Configuration;

  constructor(configuration: Configuration) {
    this.configuration = configuration;
  }

  createCheckout(checkout: StripeCreateCheckout): AxiosRequestConfig {
    const _createPaymentUrls =
    checkout.Articles.length > 0 && checkout.Articles[0].ArticleId
      ? createArticlePaymentUrls
      : createPaymentUrls;

    const { confirmationUrl } = _createPaymentUrls(
      this.configuration,
      'embedded',
      checkout.Id,
      checkout.customerEmail,
      checkout.cancellationCode
    );

    // @ts-ignore
    let payloadData: StripeCreateCheckout = {
      Articles: checkout.Articles,
      InternalReferenceId: String(checkout.Id),
      CompanyId: checkout.CompanyId,
      ConfirmationUrl: confirmationUrl.href,
      CountryCode: checkout.CountryCode || getCountryCode(this.configuration.language || 'sv'),
      UiMode: checkout.UiMode || this.uiMode,
    };

    return {
      url: this.url,
      method: "POST",
      data: payloadData,
    };
  }
}

class Payment implements PaymentInterface {
  private configuration: Configuration;

  constructor(configuration: Configuration) {
    this.configuration = configuration;
  }

  createCheckout(
    checkout: CreateCheckoutExtended | StripeCreateCheckout
  ): AxiosRequestConfig {
    if (checkout.PaymentProviderId === 1) {
      return new PaysonV1Payment(this.configuration).createCheckout(checkout);
    } else if (checkout.PaymentProviderId === 2) {
      return new PaysonV2Payment(this.configuration).createCheckout(checkout);
    } else if (checkout.PaymentProviderId === 3) {
      return new QvicklyPayment(this.configuration).createCheckout(checkout);
    } else if (checkout.PaymentProviderId === 5) {
      return new StripePayment(this.configuration).createCheckout(checkout);
    } else {
      throw new Error("Invalid PaymentProviderId");
    }
  }
}

export default Payment;
