import React from "react";
import { useAppDispatch } from "@/hooks";
import { CompanyType } from "@/types";
import { Trans } from "@lingui/macro";
import { MdCheck } from "react-icons/md";
import { Button } from "bokamera-embedded-ui";

interface Props {
  renderPaidElement?: () => void;
}

const YouHavePaid: React.FC<Props> = ({
  renderPaidElement = () => {},
}) => {
  const dispatch = useAppDispatch();

  return (
    <>
      {renderPaidElement()}

      <div className="text-center">
        <p className="text-success">
          <MdCheck size={64} />
        </p>
        <Button
          color="success"
          onClick={(ev) => {
            ev.preventDefault();
            dispatch({
              type: "START_OVER",
              payload: window.location,
            });
          }}
        >
          Ok
        </Button>
      </div>
    </>
  );
};

export default YouHavePaid;
