import * as Endpoint from "./endpoint";

import qs from "qs";

import { ConfigKeys } from "@/misc/constants";
import {
  format,
  toDate,
  addDays,
  startOfISOWeek,
  endOfISOWeek,
  startOfDay,
  addYears,
  startOfMonth,
  endOfMonth,
  addMonths,
} from "@/misc/datetime";

import {
  Configuration,
  ServiceType,
  QuantityType,
  CountryCode,
  CheckoutRequest,
  Article,
  Currency,
  CreateArticle,
  CreateArticleRebateCode,
  RebateCode,
  QvicklyCheckoutv1CreateCheckout,
  StripeUiMode,
} from "@/types";
import { IS_PROD, realmParamsMapping } from "@/misc/common";
import readConfigurationProperty from "@/misc/readConfigurationProperty";
import { definitions, paths } from "@/apitypes";
import Payment from "./paymentService";


// Temporary booking substate type
interface BookingStateType {
  [key: string]: any;
}

type BookingUserQueueItemResponse = {
  BookingUserQueue: paths["/bookinguserqueue"]["post"]["responses"]["200"]["schema"];
  ResponseStatus: {
    Message: string;
  };
};

export type CreateCheckout = {
  Id: number;
  CompanyId: string;
  CheckoutUrl: string;
  ConfirmationUrl: string;
  PaymentProviderId: number;
  CountryCode: CountryCode;
  Articles: definitions["ArticleToCreate"][];
  cancellationCode?: string;
  customerEmail: string;
  UiMode?: StripeUiMode;
};

export type CreateCheckoutPartial = Omit<CreateCheckout, 'ConfirmationUrl' | 'CheckoutUrl'>
export default class ServicesAPI {
  static getServicesList(CompanyId: string, params = {}): Promise<any> {
    let parameters = {
      CompanyId,
      Active: true,
      IncludeCustomFieldValues: true,
      IncludePrices: true,

      IncludeCustomFields: false,
      IncludeBookingCustomFields: false,
      IncludeSchedules: false,
      IncludePricesFromOtherDays: true,
      ...params,
    };

    return Endpoint.get("/services?" + qs.stringify(parameters));
  }

  static getCompany(companyId: string): Promise<any> {
    let parameters = {
      Id: companyId,
      IncludeBookingAgreements: true,
      IncludeBookingSettings: true,
      IncludeSystemSettings: true,
      IncludeCustomerCustomFields: true,
      IncludeWidgetSettings: true,
    };

    return Endpoint.get("/companies?" + qs.stringify(parameters));
  }

  /**
   * Get additional information for service
   *
   * @static
   * @param {any} company
   * @param {any} service
   * @param {any} [time=null]
   * @returns
   *
   * @memberof ServicesAPI
   */
  static getCompanyService(
    companyId: string,
    service: ServiceType,
    time?: { From: string; To: string }
  ): Promise<any> {
    let parameters: any = {
      Id: service.Id,
      CompanyId: companyId,
      Active: true,
      IncludeResources: true,
      IncludeCustomFieldValues: true,
      IncludePrices: true,

      IncludeCustomFields: true,
      IncludeBookingCustomFields: true,
      IncludeCustomerCustomFields: true,
      IncludeSchedules: false,
    };

    if (time) {
      parameters.PriceDate = format(toDate(time.From), "yyyy-MM-dd");
      parameters.PriceTime = format(toDate(time.From), "HH:mm");
    }

    return Endpoint.get("/services?" + qs.stringify(parameters));
  }

  static resetPassword(email: string) {
    // @ts-ignore
    const realm = realmParamsMapping[process.env.REACT_APP_KEYCLOAK_REALM];

    return Endpoint.post("/users/forgotpassword", {
      Email: email,
      Realm: realm,
    });
  }

  static getTimesList(
    companyId: string,
    booking: BookingStateType,
    service: ServiceType,
    configuration: Configuration,
    duration?: number // minutes
  ): Promise<any> {
    let fromDate;
    let toDate;
    let showPerResource = false;

    const bookLayout = configuration[ConfigKeys.BOOK_LAYOUT];
    const timesLayout = configuration[ConfigKeys.TIMES_LAYOUT];

    if (
      timesLayout === ConfigKeys.TIMES_LAYOUT_WEEK ||
      timesLayout === ConfigKeys.TIMES_LAYOUT_WEEKLY_OVERVIEW
    ) {
      fromDate = startOfISOWeek(configuration.navigationDate);
      toDate = endOfISOWeek(configuration.navigationDate);
    } else if (timesLayout === ConfigKeys.TIMES_LAYOUT_MONTH) {
      fromDate = startOfISOWeek(startOfMonth(configuration.navigationDate));
      toDate = endOfMonth(configuration.navigationDate);
    } else if (timesLayout === ConfigKeys.TIMES_LAYOUT_QUARTER) {
      fromDate = startOfISOWeek(startOfMonth(configuration.navigationDate));
      toDate = endOfMonth(addMonths(configuration.navigationDate, 2));
    } else if (timesLayout === ConfigKeys.TIMES_LAYOUT_LIST) {
      fromDate = startOfDay(new Date());
      toDate = addYears(new Date(), 1);
    }

    showPerResource = bookLayout === ConfigKeys.BOOK_LAYOUT_RESOURCE_BASED;

    const resources = booking.resources;
    const resourceCount = booking.resourceCount;
    const parameters = {
      CompanyId: companyId,
      ServiceId: service.Id,
      From: fromDate ? fromDate : undefined,
      To: toDate ? toDate : undefined,
      ShowPerResource: showPerResource,
      Resources: JSON.stringify(
        Object.keys(resources).map((key) => {
          return { ResourceTypeId: key, ResourceId: resources[key] };
        })
      ).replace(/"/gi, ""),
      NumberOfResources: resourceCount,
      ...(duration ? { Duration: duration } : {}),
    };

    return Endpoint.get(
      `/services/${parameters.ServiceId}/availabletimes?` +
        qs.stringify(parameters)
    );
  }

  static getNextAvailableTime(
    companyId: string,
    booking: BookingStateType,
    service: ServiceType,
    configuration: Configuration,
    duration?: number
  ): Promise<any> {
    const resources = booking.resources;
    const resourceCount = booking.resourceCount;
    const parameters = {
      CompanyId: companyId,
      ServiceId: service.Id,
      ShowPerResource: false,
      From: format(new Date(), "yyyy-MM-dd"),
      To: format(addDays(new Date(), 150), "yyyy-MM-dd"),
      Resources: JSON.stringify(
        Object.keys(resources).map((key) => {
          return { ResourceTypeId: key, ResourceId: resources[key] };
        })
      ).replace(/"/gi, ""),
      NumberOfResources: resourceCount,
      ...(duration ? { Duration: duration } : {}),
    };

    return Endpoint.get(
      `/services/${parameters.ServiceId}/nextfreetime?` +
        qs.stringify(parameters)
    );
  }

  static authenticate(parameters: any): Promise<any> {
    return Endpoint.post("/authenticate?" + qs.stringify(parameters));
  }

  static restore(parameters: any): Promise<any> {
    return Endpoint.post("/users/forgotpassword?" + qs.stringify(parameters));
  }

  static logout(): Promise<any> {
    return Endpoint.post("/authenticate/logout");
  }

  static register(parameters: any): Promise<any> {
    return Endpoint.post("/customers", parameters);
  }

  static createAccount(parameters: any): Promise<any> {
    return Endpoint.post("/users", parameters);
  }

  // Authenticated requests

  static getUser(): Promise<any> {
    let parameters = {};

    return Endpoint.get("/users" + qs.stringify(parameters), undefined);
  }

  static book(values: any): Promise<any> {
    return Endpoint.post("/bookings", values);
  }

  static createRebateFromArticle(
    payload: CreateArticleRebateCode & {
      Recipient?: {
        Firstname: string;
        Lastname: string;
        Email: string;
        Message: string;
      };
    }
  ): Promise<Required<RebateCode>> {
    return Endpoint.post("/rebatecodes/fromarticle", payload);
  }

  static createQuicklyV1Checkout(payload: QvicklyCheckoutv1CreateCheckout) {
    return Endpoint.post("/payment/billmate/v1/checkout", payload);
  }

  static createCheckout(checkout: CreateCheckoutPartial, configuration: Configuration): Promise<any> {    
    const requestPayload = new Payment(configuration).createCheckout(checkout);

    return Endpoint.post(
      requestPayload.url as string,
      requestPayload.data
    );
  }

  static getPromoCode(params: {
    CompanyId: string;
    ServiceId: number;
    RebateCodeSign: string;
    Date: string;
    CustomerEmail?: string;
  }): Promise<any> {
    return Endpoint.get("/rebatecodes/getbysign?" + qs.stringify(params));
  }

  static decrypt({ text }: { text: string }) {
    return Endpoint.get(
      `/encryption/decrypt?${qs.stringify({
        Text: text,
      })}`
    );
  }

  static getStripeAccount({ companyId }: { companyId: string }) {
  
    return Endpoint.get(
      `/payment/stripe/v1/account?${qs.stringify({ CompanyId: companyId })}`
    );
  }

  static getBooking(parameters: any) {
    return Endpoint.get<any>(
      "/bookings?IncludeServiceInformation=true&" + qs.stringify(parameters)
    );
  }

  static addUserToQueue(
    params: paths["/bookinguserqueue"]["post"]["parameters"]["query"]
  ) {
    return Endpoint.post<BookingUserQueueItemResponse>(
      "/bookinguserqueue",
      params
    );
  }

  static calculatePrice(params: {
    CompanyId: string;
    ServiceId: number;
    Interval: {
      From: string;
      To?: string;
    };
    RebateCodeIds: number[];
    Quantities: QuantityType[];
    CustomerEmail: string;
  }): Promise<any> {
    return Endpoint.put(`/services/${params.ServiceId}/calculateprice`, params);
  }

  static getArticles(
    params: Partial<definitions["GetArticles"]>
  ): Promise<Article[]> {
    return Endpoint.get(`/articles?${qs.stringify(params)}`);
  }

  static getCurrencies(): Promise<Currency[]> {
    return Endpoint.get(`/currencies?${qs.stringify({ Active: true })}`);
  }

  static buyArticle(data: CreateArticle): Promise<Article> {
    return Endpoint.post("/articles", data);
  }
}
