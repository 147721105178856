import {
  createSlice,
  createListenerMiddleware,
  PayloadAction,
} from "@reduxjs/toolkit";
import { TypedListener } from "@/store";
import { merge } from "lodash";

export const STRIPE_AUTH_PATHNAME = "/stripe-auth";

interface Entity {
  accountId: string;
}

interface InitialState {
  entity: Entity | null;
  isLoading: boolean;
  error: any;
}

export const initialState: InitialState = {
  entity: null,
  error: null,
  isLoading: false,
};

// Middlewares
export const stripeAuthListener = createListenerMiddleware();
export const startStripeAuthListener =
  stripeAuthListener.startListening as TypedListener;

// Slice
const stripeAuthSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
    setAccountId: (state, action: PayloadAction<string>) => {
      if(state.entity?.accountId) {
        state.entity.accountId = action.payload;
      } else {
        state.entity = merge(state.entity, { accountId: action.payload });
      }
    }
  },
});

export const { setAccountId } = stripeAuthSlice.actions;

export default stripeAuthSlice;
