import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';

import { MdPrint } from 'react-icons/md';
import BEMHelper from 'react-bem-helper';
import { format, toDate } from '@/misc/datetime';

// Partials
import Company from '@/components/partials/Company';
import Summary from '@/components/partials/Summary';

// Elements
import { Button } from 'bokamera-embedded-ui';
import Panel from '@/components/elements/Panel';
import Spinner from '@/components/elements/Spinner';
import { BookingStatusIds } from '@/misc/constants';
import { createPaymentUrls, getCountryCode, tryParseJSONObject } from '@/misc/common';
import readConfigurationProperty from "@/misc/readConfigurationProperty";

import './Completed.css';
import { Trans } from '@lingui/macro';
import { useHistory } from 'react-router';
import { ApplicationState, persistor } from '@/store';
import { CreateCheckout, CreateCheckoutPartial } from '@/api/services';
import { useLazyGetRebateCodesQuery } from '@/services/bmApi';
import { useAppSelector } from '@/hooks';
import StripeCheckout from '@/components/StripeCheckout';
import { omit } from 'lodash';
import { withPaymentScroll, WithPaymentScrollProps } from '@/hoc/withPaymentScroll';
import { setAccountId } from '@/reducers/stripeSlice';

interface Props extends ConnectedProps<typeof connector>, WithPaymentScrollProps {}

const c = new BEMHelper({ name: 'Completed' });


export const Completed: React.FC<Props> = (props) => {
    const { company, configuration, checkout, dispatch, customerEmail, completePaymentPaymentRef, iOSSafari } = props;
    
    const history = useHistory();
    const [rebateCodes] = useLazyGetRebateCodesQuery();
    const customerId = useAppSelector(s => s.user.customerId);

    const booking = props.booking?.data;
    const [completePayment, setCompletePayment] = useState(false);

    // NOTE: Check this shape
    const paymentEnabled = booking?.Service?.IsPaymentEnabled;

    const payWithPaysonV1 = company?.BookingSettings?.PaymentProviderId === 1;
    const payWithPaysonV2 = company?.BookingSettings?.PaymentProviderId === 2;
    const payWithBillmate = company?.BookingSettings?.PaymentProviderId === 3;
    const payWithStripe = company?.BookingSettings?.PaymentProviderId === 5;

    const statusRequiresPayment = booking?.StatusId === 5;

    const paymentIsEmbedded = payWithPaysonV2 || payWithBillmate || payWithStripe;
    const needToPay = paymentEnabled && statusRequiresPayment;

    useEffect(() => {
        if(booking) {
            rebateCodes({ CustomerId: customerId })
        }
        
        
        if (
          booking &&
          needToPay &&
          !iOSSafari &&
          company?.Id &&
          !configuration.paymentButton &&
          company?.BookingSettings?.PaymentProviderId
        ) {

          const payload: CreateCheckoutPartial = {
            CompanyId: company?.Id,
            Id: booking.Id,
            PaymentProviderId: company.BookingSettings.PaymentProviderId,
            CountryCode: getCountryCode(
              readConfigurationProperty("language", "se")
            ),
            Articles: [
              {
                ArticleTypeId: 1,
                ArticleId: 0,
                Quantity: 1,
              },
            ],
            cancellationCode: booking.CancellationCode,
            customerEmail: booking.Customer.Email,
          };

          dispatch({
            type: "CREATE_CHECKOUT_REQUEST",
            payload,
          });
        }
    }, [
        dispatch,
        configuration.paymentConfirmationURL,
        company,
        booking,

        paymentIsEmbedded,
        needToPay,
    ]);

    useEffect(() => {
        const statusRequiresPayment = booking?.StatusId === 5;
        const paymentIsEmbedded = payWithPaysonV2 || payWithBillmate;
        const needToPay = paymentEnabled && statusRequiresPayment;

        window.onbeforeunload = () => {
            persistor.pause();
            dispatch({ type: 'PURGE_PERSISTED' });
          }

        window.onmessage = (ev) => {
            try {
                const data = tryParseJSONObject(ev.data);
                if(data && data.event === 'purchase_initialized') {
                    const billmateContainer: HTMLIFrameElement | null = document.querySelector(`[src^="${checkout.data.Url}"]`);
                    billmateContainer?.contentWindow?.postMessage('purchase_complete', '*');
                }
            } catch (error) {
                // intentionally left blank
            }
        };

        if (!!checkout.data && needToPay && paymentIsEmbedded) {
            
            if(!document.getElementById('paysonContainer') && !iOSSafari && !configuration.paymentButton) {
                const div = document.createElement('div');
                div.innerHTML = checkout.data.Snippet;
                // @ts-ignore
                window?.bookingAppContainer?.getRootNode()?.host?.parentNode?.prepend(div);
            }

            const intervalId = setInterval(() => {
                const paysonContainer = window?.bookingAppContainer?.querySelector('#paysonContainer')
                    || document.getElementById('paysonContainer');
                
                completePaymentPaymentRef(paysonContainer as HTMLElement);

                if (paysonContainer) {
                    const scriptParentNode: Node | null = paysonContainer.parentNode;
                    if (!!scriptParentNode) {
                        // How to rewrite it in correct way?
                        // @ts-ignore
                        const scriptNode = scriptParentNode.getElementsByTagName('script')[0];
                        const scriptNodeWithContent = document.createElement('script');
                        scriptNodeWithContent.src = scriptNode.src;
                        document.head.appendChild(scriptNodeWithContent);
                        clearInterval(intervalId);
                    }
                }
            }, 1000);
        } else if (needToPay && (iOSSafari || configuration.paymentButton)) {
            setCompletePayment(true);
        }

        if(checkout.data && payWithStripe && checkout.data.StripeAccount) {
            dispatch(setAccountId(checkout.data.StripeAccount));
        } else if(checkout.data && payWithStripe && checkout.data.Url && window.top?.location) {
            window.top.location = checkout.data.Url;
        }
    }, [checkout.data, booking?.Service?.IsPaymentEnabled, booking?.StatusId, payWithPaysonV2, payWithBillmate, payWithStripe]);

    if (!booking || !company) return null;

    if (checkout.hasError) {
        return (
            <Panel>
                <Row>
                    <Col xs={12}>
                        <Trans id="completed.error" />
                    </Col>
                </Row>
                {checkout?.error?.ResponseStatus?.Message ? (
                    <Row>
                        <Col xs={12}>{checkout.error.ResponseStatus?.Message}</Col>
                    </Row>
                ) : null}
            </Panel>
        );
    }

    return (
        <Panel>
            <div id="section-to-print">
                {needToPay ? (
                <h4 className="text-left text-danger" style={{ marginBottom: 20, padding: '0.3rem' }}>
                        <Trans id="youNeedToPayTheBooking"></Trans>
                    </h4>
                    ) : (
                    <h4 className="text-left text-success" style={{ marginBottom: 20, padding: '0.3rem' }}>
                        <Trans id="bookingConfirmation"></Trans>
                </h4>
                )}
                {needToPay && payWithPaysonV1 && checkout.data && !iOSSafari && !configuration.paymentButton ? (
                    <Alert color="warning" className="clearfix">
                        <div>
                            {booking.PaymentExpiration && (
                                <p>
                                    <Trans id="bookingHasFee"></Trans>{' '}
                                    <strong>
                                        {format(toDate(booking.PaymentExpiration), 'd MMM, p')}
                                    </strong>
                                </p>
                            )}
                            {!booking.PaymentExpiration && (
                                <p>
                                    <Trans id="bookingHasFee1"></Trans>
                                </p>
                            )}
                            <p>
                                <Trans id="instructionsHasBeenSentToEmail"></Trans>
                            </p>
                        </div>
                        <a
                            className="float-right hidden-print"
                            href={checkout?.data?.Snippet}
                            target="_blank"
                        >
                            <Button>
                                <Trans id="payNow"></Trans>
                            </Button>
                        </a>
                    </Alert>
                ) : null}
                {company?.BookingSettings?.BookingReceiptMessage && (
                    <p  style={{ marginBottom: 20, padding: '0.3rem' }}>
                        {company.BookingSettings.BookingReceiptMessage}
                    </p> 
                )}
                {booking.StatusId === BookingStatusIds.RESERVED && (
                    <Alert color="success">
                        <Trans id="yourBookingIsReserved"></Trans>
                    </Alert>
                )}
                {checkout.isLoading ? (
                    <div className="hidden-print">
                        <div>
                            <Spinner {...c('paymentLoading')} />
                        </div>
                        <Alert color="warning" className="clearfix">
                            <Trans id="yourBookingMustBePaid"></Trans>
                        </Alert>
                    </div>
                ) : null}
                {checkout.data && !iOSSafari && !configuration.paymentButton && payWithPaysonV2  ? (
                    <Row className="hidden-print" style={{ marginBottom: '1rem' }}>
                        <Col xs={12}>
                            <div ref={completePaymentPaymentRef} dangerouslySetInnerHTML={{ __html: checkout.data.Snippet }} />
                        </Col>
                    </Row>
                ): null}

                {
                checkout.data &&
                checkout.data.Status === 'open' &&
                !iOSSafari &&
                !configuration.paymentButton &&
                payWithStripe ? (
                    <div ref={completePaymentPaymentRef}>
                        <Row className="hidden-print" style={{ marginBottom: "1rem" }}>
                            <Col xs={12}>
                                <StripeCheckout clientSecret={checkout.data.ClientSecret} />
                            </Col>
                        </Row>
                    </div>
                ) : null}
                
                {completePayment ? (
                    <div ref={completePaymentPaymentRef} {...c('userInitiatedPayment')} >
                        <Button
                            primary
                            onClick={() => {
                                try {
                                    const payWithStripe = company?.BookingSettings?.PaymentProviderId === 5;

                                    if(window?.top?.location) {
                                        const bokameraSiteUrl = process.env.REACT_APP_BOKAMERA_SITE_URL;
                                        const { confirmationUrl } = createPaymentUrls(
                                            configuration,
                                            payWithStripe ? 'embedded' : 'hosted',
                                            booking.Id,
                                            booking.Customer.Email,
                                            booking.CancellationCode
                                        );

                                        let paymentUrl = `${bokameraSiteUrl}/payment?bookingId=${booking.Id}&companyId=${company.Id}&email=${customerEmail}&paymentProviderId=${company?.BookingSettings?.PaymentProviderId}`;

                                        if(confirmationUrl) {
                                            paymentUrl = `${paymentUrl}&confirmationUrl=${encodeURIComponent(typeof confirmationUrl === 'string' ? confirmationUrl : confirmationUrl.href)}`
                                        }

                                        window.top.location = paymentUrl;
                                    }
                                } catch (error) {
                                    
                                }
                            }}
                        >
                            <Trans id="completePayment" />
                        </Button>
                    </div>
                ) : null}
                {checkout.data && payWithBillmate && !iOSSafari && !configuration.paymentButton ? (
                    <iframe
                        ref={completePaymentPaymentRef}
                        src={`${checkout.data.Url}?activateJsEvents=1`}
                        data-testid="billmateContainer"
                        sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-scripts allow-same-origin allow-forms allow-popups allow-popups-to-escape-sandbox"
                        width="100%"
                        height="900px"
                        frameBorder="0"
                    ></iframe>
                ): null}
                {!checkout.isLoading ? (<div>
                    <Row {...c('body')}>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <Company />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            <Summary finishedBooking={booking} />
                        </Col>
                    </Row>
                    <Row className="hidden-print">
                        <Col xs={12}>
                            <Button
                                className="button float-left"
                                onClick={() => {
                                    history.push('/');
                                    props.dispatch({
                                        type: 'RESET_FORM',
                                    });
                                }}
                            >
                                <Trans id="bookANewTime"></Trans>
                            </Button>
                            <Button
                                className="button float-right"
                                onClick={() => {
                                    window.print();
                                }}
                            >
                                <MdPrint /> <Trans id="print"></Trans>
                            </Button>
                        </Col>
                    </Row>
                </div>) : null}
            </div>
        </Panel>
    );
};

const mapStateToProps = ({ booking, company, configuration, checkout, form, user }: ApplicationState) => ({
    customerEmail: user?.data?.CustomerProfile?.Email || form?.customerForm?.values?.Customer?.Email,
    booking,
    company: company.data,
    configuration: configuration.data,
    checkout,
});

const connector = connect(mapStateToProps);

export default compose<React.ComponentType>(
    connector,
    withPaymentScroll
)(Completed);
