import React from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import BEMHelper from 'react-bem-helper';
import { UncontrolledTooltip } from 'reactstrap';

import { format, startOfWeek, toDate, addDays, isSameDay, isBefore } from '@/misc/datetime';
import { formatFromTo } from '@/misc/common';

import './CalendarWeek.css';
import {
    Configuration,
    TimesStateType,
    ServiceType,
    TimeType,
    CompanyStateType,
    BookingStateType,
} from '@/types';
import { MdInfoOutline } from 'react-icons/md';
import { Trans } from '@lingui/macro';
import { ApplicationState } from '@/store';

const c = new BEMHelper({
    name: 'CalendarWeek',
});

interface Props {
    configuration: Configuration;
    times: TimesStateType;
    service: ServiceType;
    company: CompanyStateType;
    dispatch: Dispatch<any>;
}

interface OwnProps {
    booking?: BookingStateType;
    service?: ServiceType;
}

const tooltipContainer = document.getElementById('bokamera-embedded');

export class CalendarWeek extends React.Component<Props> {
    render() {
        const { configuration, company } = this.props;
        const times = this.props.times[this.props.service.Id];

        const headerCells = [];
        const cells = [];

        for (let i = 0; i < 7; i++) {
            const date = addDays(startOfWeek(toDate(configuration.navigationDate)), i);

            headerCells.push(<HeaderCell key={i} date={date} />);

            let filteredTimes = times.times.filter(validTime).filter((time) => isSameDay(toDate(time.From), date));
            if (!company?.BookingSettings?.EnableShowBookedTimes) {
                filteredTimes = filteredTimes.filter((time) => time.Free > 0);
            }

            cells.push(
                <div
                    {...c('column', {
                        noTimes: filteredTimes.length === 0,
                        loading: times.isLoading,
                    })}
                    key={i}
                    style={{ width: `${100 / 7}%` }}
                >
                    {filteredTimes.length > 0 &&
                        filteredTimes.map((time: TimeType) => this.renderTime(time))}

                    {filteredTimes.length === 0 && <Trans id="noTimes"></Trans>}
                </div>
            );
        }

        return (
            <div {...c()}>
                <div {...c('head')}>{headerCells}</div>
                <div {...c('body')}>{cells}</div>
            </div>
        );
    }

    renderTime = (time: TimeType) => {
        const { service, company, configuration } = this.props;
        const { bookedTimeSlotText, showEndTimeOnTimeslots } = configuration;
        const times = this.props.times[this.props.service.Id];
        const noTimeExceptions = time.ExceptionTexts?.length === 0;
        const addToQueue = !!(time.Free === 0 && service.EnableBookingQueue) && noTimeExceptions;
        const fullyBooked = time.Free === 0 && !addToQueue ;
        const id = `timeslot-${service.Id}-${format(time.From, 'yyyy-MM-dd-HH-mm')}`;
        const showTooltip =
        fullyBooked && (time.ExceptionTexts?.length > 0 && !!time.ExceptionTexts[0].ReasonPublic);

        return (
            <React.Fragment key={time.From}>
                <button
                    data-testid={`button-${id}`}
                    id={id}
                    {...c('timeSlot', { addToQueue, fullyBooked })}
                    onClick={() => {
                        if (!fullyBooked) {
                            this.props.dispatch({
                                type: 'SELECT_TIME',
                                service: this.props.service,
                                time: time,
                            });
                        }
                    }}
                >
                    {(time.Free > 0 || !bookedTimeSlotText) && (
                        <>
                            <span {...c('time')}>
                                {formatFromTo(time.From, time.To, {
                                    renderEndTimeIfSameDay: showEndTimeOnTimeslots,
                                })}
                            </span>
                            {showTooltip && (
                                <>
                                    {' '}
                                    <MdInfoOutline />
                                </>
                            )}
                        </>
                    )}
                    {(service.GroupBooking.Active || service.MultipleResource.Active) &&
                        time.Free > 0 &&
                        company &&
                        company?.BookingSettings?.ShowFreeTimesLeft && (
                            <div {...c('freeTimesLeft')}>
                                {time.Free} {time.Free === 1 ? times.single : times.multiple}
                            </div>
                        )}
                    {time.Free === 0 && bookedTimeSlotText}
                    {(service.GroupBooking.Active || service.MultipleResource.Active) &&
                        time.Free === 0 &&
                        !bookedTimeSlotText && (
                            <div {...c('fullyBooked')}>
                                <Trans id="fullyBooked"></Trans>
                            </div>
                        )}
                    {addToQueue ? (<Trans id="addToQueue" />) : null}
                </button>
                {showTooltip && (
                    <UncontrolledTooltip
                        target={id}
                        container={tooltipContainer ? tooltipContainer : undefined}
                    >
                        {time.ExceptionTexts[0].ReasonPublic}
                    </UncontrolledTooltip>
                )}
            </React.Fragment>
        );
    };
}

function validTime(time: TimeType) {
    return isBefore(time.From, time.To);
}

function HeaderCell({ date }: { date: Date }) {
    return (
        <div
            {...c('dayHeader', {
                active: isSameDay(new Date(), date),
            })}
        >
            <div {...c('day')}>{format(date, 'd')}</div>
            <div {...c('month')}>{format(date, 'MMM')}</div>
            <div {...c('dayOfWeek')}>{format(date, 'E')}</div>
        </div>
    );
}

const mapStateToProps = ({ configuration, company, services, times }: ApplicationState) => ({
    configuration: configuration.data,
    company: company.data,
    services,
    times,
});

export default compose(connect(mapStateToProps))(CalendarWeek) as React.ComponentType<OwnProps>;
