import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { orderBy } from "lodash";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { Collapse } from "react-collapse";
import BEMHelper from "react-bem-helper";
import { Card } from "reactstrap";

import { groupArray, scrollToElement, sortByDashes } from "@/misc/common";
import "./Services.css";
import { Article as ArticleType, ServiceType, CompanyType } from "@/types";
import { GroupedServicesType } from "./Services";
import Service from "./Service";
import Article from "./Article";
import ServiceStructuredData from "./ServiceStructuredData";

type ArticleWithGroup = ArticleType & { Group: string };

export interface RowsGroupedProps {
  services: ServiceType[];
  articles: ArticleWithGroup[];
  company: CompanyType;

  /**
   * index numbers of the ones that are expanded
   */
  initiallyExpanded?: number[];
}

export const c = new BEMHelper({
  name: "Services",
});

export const RowsGrouped: FunctionComponent<RowsGroupedProps> = ({
  services = [],
  articles = [],
  initiallyExpanded = [],
  company,
}) => {
  const orderedArticles = orderBy(
    articles.map((article) => {
      if (!article.Group) {
        article.Group = "Other";
      }
      return article;
    }),
    [(article) => article.Group.toLowerCase()]
  );

  const orderedServices = orderBy(
    services.map((service) => {
      if (!service.Group) {
        service.Group = "Other";
      }
      return service;
    }),
    [(service) => service.Group.toLowerCase()]
  );

  const orderedEntities = [...orderedServices, ...orderedArticles];

  const groupedServices = groupArray(orderedEntities, "Group");

  const sortedGroupNames = orderBy(sortByDashes(Object.keys(groupedServices)), [
    (groupName) => groupName.toLowerCase(),
  ]);
  const [activeGroupId, setActiveGroupId] = useState("");
  const [expanded, setExpanded] = useState(initiallyExpanded);

  const onCollapseChange = (groupId: string) => {
    const newExpandedCollapsedIndex = sortedGroupNames
      .map((group) => convertToSectionNames(group))
      .indexOf(groupId);

    const alreadyThere = expanded.indexOf(newExpandedCollapsedIndex);

    if (alreadyThere !== -1 && alreadyThere !== 0) {
      const expandedClone = [...expanded];
      expandedClone.splice(alreadyThere);
      setExpanded(expandedClone);
    } else if (alreadyThere === 0) {
      const expandedClone = [...expanded];
      expandedClone.shift();
      setExpanded(expandedClone);
    } else {
      setExpanded([...expanded, newExpandedCollapsedIndex]);
    }
  };

  const gotoHeading = (groupName: string) => {
    const id = convertToSectionNames(groupName);
    setActiveGroupId(id);

    const newExpandedCollapsedIndex = sortedGroupNames
      .map((group) => convertToSectionNames(group))
      .indexOf(id);

    const alreadyThere = expanded.indexOf(newExpandedCollapsedIndex);

    // this means it's not there
    if (alreadyThere === -1) {
      setExpanded([...expanded, newExpandedCollapsedIndex]);
    } else {
      setExpanded(expanded.filter((e) => e !== newExpandedCollapsedIndex));
      setTimeout(() => {
        setExpanded([...expanded, newExpandedCollapsedIndex]);
      }, 50);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const el = document.getElementById(activeGroupId);
      const topTo = el?.getBoundingClientRect().top;
      if (topTo) {
        scrollToElement({ element: el });

        setActiveGroupId("");
      }
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [activeGroupId]);

  return (
    <div {...c("ServicesRowsGrouped")}>
      <h4>
        <small className="text-muted">
          <Trans id="clickOnServiceGroup" />
        </small>
      </h4>
      <div className="group-anchors">
        {sortedGroupNames.map((groupName) => (
          <Link
            key={groupName}
            data-testid="groupAnchor"
            onClick={(evt) => {
              evt.preventDefault();
              gotoHeading(groupName);
            }}
            className="group-anchors__item"
            to={{
              hash: convertToSectionNames(groupName),
            }}
          >
            {groupName}
          </Link>
        ))}
      </div>
      {sortedGroupNames.map((groupName, i) => (
        <CollapsibleRow
          key={i}
          expanded={expanded.includes(i)}
          groupName={groupName}
          groupedServices={groupedServices}
          onCollapseChange={onCollapseChange}
          company={company}
        />
      ))}
    </div>
  );
};

interface CollapsibleRowProps {
  company: CompanyType;
  groupName: string;
  groupedServices: GroupedServicesType;
  expanded: boolean;
  onCollapseChange: (groupId: string) => void;
}

export const CollapsibleRow: React.FC<CollapsibleRowProps> = ({
  groupName,
  groupedServices,
  expanded = false,
  onCollapseChange,
  company,
}) => {
  const sectionRef = useRef<HTMLElement>(null);
  const groupId = convertToSectionNames(groupName);

  return (
    <section
      {...c("collapsible-row")}
      key={groupName}
      id={groupId}
      ref={sectionRef}
    >
      <Card
        className="collapsible-card"
        onClick={() => onCollapseChange(groupId)}
      >
        <h4 {...c("title")}>
          <span>{groupName}</span>
          <span className={expanded ? "icon icon--expanded" : "icon"} />
        </h4>
      </Card>
      <Collapse key={`${groupName}-collapse`} isOpened={expanded}>
        {groupedServices[groupName]
          .sort((a, b) => a.SortOrder - b.SortOrder)
          .map((service: any) => {
            const isArticle = service && service.ArticleTypeId;

            return isArticle ? (
              <>
                <Article article={service} />
              </>
            ) : (
              <>
                <Service key={service.Id} service={service} />
                <ServiceStructuredData service={service} company={company} />
              </>
            );
          })}
      </Collapse>
    </section>
  );
};

export const convertToSectionNames = (name: string = "") => {
  const cleanName = name
    .replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
    .replace(/\//gi, "-")
    .replace(/\s/g, "-")
    .toLowerCase();

  return cleanName;
};
