import {
    format as _format,
    formatDistanceStrict as _formatDistanceStrict,
    startOfWeek as _startOfWeek,
    endOfWeek as _endOfWeek,
    toDate as _toDate,
    parse as _parse,
    startOfISOWeek as _startOfISOWeek,
    endOfISOWeek as _endOfISOWeek,
    startOfDay as _startOfDay,
    addYears as _addYears,
    startOfMonth as _startOfMonth,
    endOfMonth as _endOfMonth,
    addWeeks as _addWeeks,
    addMonths as _addMonths,
    addQuarters as _addQuarters,
    isSameDay as _isSameDay,
    subMinutes as _subMinutes,
    subHours as _subHours,
    subDays as _subDays,
    subQuarters as _subQuarters,
    addMinutes as _addMinutes,
    addHours as _addHours,
    addDays as _addDays,
    eachDayOfInterval as _eachDayOfInterval,
    getMonth as _getMonth,
    getISOWeek as _getISOWeek,
    setMonth as _setMonth,
    isToday as _isToday,
    isAfter as _isAfter,
    isBefore as _isBefore,
    differenceInDays as _differenceInDays,
    parseISO as _parseISO,
    isWithinInterval as _isWithinInterval
} from 'date-fns';
import { enUS, nb, sv, fi } from 'date-fns/locale';
import { ConfigKeys } from './constants';
import readConfigurationProperty from '@/misc/readConfigurationProperty';
import { Args, ParsedSeconds } from '@/types';
import { t } from '@lingui/macro'


//==================================================================================
// Module contains localised wrappers for date-fns functions and some custom utils
//==================================================================================

const locales: { [key: string]: Locale } = {
    en: enUS,
    no: nb,
    sv,
    fi,
};

// @ts-ignore
const getLocale = () => locales[readConfigurationProperty(ConfigKeys.LANGUAGE) || 'sv'];

export const parse = (
    dateString: string,
    formatString: string,
    backupDate: Date | string,
    options?: {} | undefined
): Date => _parse(dateString, formatString, toDate(backupDate), options);

export const toDate = (value: Date | string | number): Date =>
    typeof value === 'string' ? _parseISO(value) : _toDate(value);

export function format(date: Date | string, formatStr: string): string {
    return _format(toDate(date), formatStr, { locale: getLocale() });
}

export function startOfWeek(date: Date): Date {
    return _startOfWeek(date, { weekStartsOn: 1 });
}

export function endOfWeek(date: Date): Date {
    return _endOfWeek(date, { weekStartsOn: 1 });
}

export type FormatDistanceStrictOptions = Args<typeof _formatDistanceStrict>['args'][2];
export function formatDistanceStrict(from: Date, to: Date, options: FormatDistanceStrictOptions = {} ): string {
    const wordMapping = [
        'noll',
        'en',
        'två',
        'tre',
        'fyra',
        'fem',
        'sex',
        'sju',
        'åtta',
        'nio',
        'tio',
        'elva',
        'tolv',
    ];

    let formatted = _formatDistanceStrict(from, to, { locale: getLocale(), ...options });

    wordMapping.forEach((word, index) => {
        formatted = formatted.replace(word, String(index));
    });

    return formatted;
}

export const addMinutes = (date: Date | string, amount: number): Date =>
    _addMinutes(toDate(date), amount);

export const addHours = (date: Date | string, amount: number): Date =>
    _addHours(toDate(date), amount);

export const addDays = (date: Date | string, amount: number): Date =>
    _addDays(toDate(date), amount);

export const addWeeks = (date: Date | string, amount: number): Date =>
    _addWeeks(toDate(date), amount);

export const addMonths = (date: Date | string, amount: number): Date =>
    _addMonths(toDate(date), amount);

export const addQuarters = (date: Date | string, amount: number): Date =>
    _addQuarters(toDate(date), amount);

export const addYears = (date: Date | string, amount: number): Date =>
    _addYears(toDate(date), amount);

export const subMinutes = (dateLeft: Date | string, amount: number): Date =>
    _subMinutes(toDate(dateLeft), amount);

export const subHours = (dateLeft: Date | string, amount: number): Date =>
    _subHours(toDate(dateLeft), amount);

export const subDays = (dateLeft: Date | string, amount: number): Date =>
    _subDays(toDate(dateLeft), amount);

export const subQuarters = (dateLeft: Date | string, amount: number): Date =>
    _subQuarters(toDate(dateLeft), amount);

export const isSameDay = (dateLeft: Date | string, dateRight: Date | string): boolean =>
    _isSameDay(toDate(dateLeft), toDate(dateRight));

export const getMonth = (date: Date | string): number => _getMonth(toDate(date));

export const setMonth = (date: Date | string, month: number): Date =>
    _setMonth(toDate(date), month);

type IntervalWithStrings = {
    start: number | Date | string;
    end: number | Date | string;
};

export const toDateInterval = (intervalWithStrings: IntervalWithStrings): Interval => ({
    start: toDate(intervalWithStrings.start),
    end: toDate(intervalWithStrings.end),
});

export const eachDayOfInterval = (interval: IntervalWithStrings): Date[] =>
    _eachDayOfInterval(toDateInterval(interval));

export const startOfDay = (date: Date | string): Date => _startOfDay(toDate(date));

export const startOfISOWeek = (date: Date | string): Date => _startOfISOWeek(toDate(date));

export const startOfMonth = (date: Date | string): Date => _startOfMonth(toDate(date));

export const endOfISOWeek = (date: Date | string): Date => _endOfISOWeek(toDate(date));

export const endOfMonth = (date: Date | string): Date => _endOfMonth(toDate(date));

export const getISOWeek = (date: Date | string): number => _getISOWeek(toDate(date));

export const isToday = (date: Date | string): boolean => _isToday(toDate(date));

export const isAfter = (date: Date | string, dateToCompare: Date | string): boolean =>
    _isAfter(toDate(date), toDate(dateToCompare));

export const isBefore = (date: Date | string, dateToCompare: Date | string): boolean =>
    _isBefore(toDate(date), toDate(dateToCompare));

export const differenceInDays = (dateLeft: Date | string, dateRight: Date | string) =>
    _differenceInDays(toDate(dateLeft), toDate(dateRight));

export const getFormattedDateTime = (date: Date | string) => {
    const dateTimeFormat = 'd MMM, p';
    
    if(getLocale().code === 'sv') {
        return `${format(date, 'd')} ${format(date, 'MMM')}, ${format(date, 'p')}`
    }

    return `${format(date, dateTimeFormat)}`
}

export const isWithinInterval = (date: Date | string, interval: {
    start:  Date | string,
    end:  Date | string
}) => {
    return _isWithinInterval(toDate(date), {
      start: toDate(interval.start),
      end: toDate(interval.end),
    });
}

export const getDayOfWeek = (dayIndex: number) => {
    const firstDOW = startOfWeek(new Date())
    const shortWeekDaysArray = Array.from(Array(7)).map((e, i) => format(addDays(firstDOW, i), 'EEEEEE'));

    return shortWeekDaysArray[dayIndex - 1];
}

export const formatTimeToWords = (
  lengthInMinutes: number,
  unit: "minute" | "hour" | "day",
  i18n: any,
  style: 'normal' | 'narrow' | 'short' | 'hide' = 'normal'
) => {
  const formattedMinutes = parseSeconds(lengthInMinutes * 60);
  const { days, minutes, hours } = formattedMinutes;

  const sliceArgs = style === 'short' ? [0, 1] : style === 'narrow' ? [0, 3] : [];
  const daySingular = style !== 'hide' ? i18n._(t`common.day`).slice(...sliceArgs) : '';
  const dayPlural = style !== 'hide' ? i18n._(t`common.days`).slice(...sliceArgs) : '';
  const hourSingular = style !== 'hide' ? i18n._(t`common.hour`).slice(...sliceArgs) : '';
  const hourPlural = style !== 'hide' ? i18n._(t`common.hours`).slice(...sliceArgs) : '';
  const minuteSingular = style !== 'hide' ? i18n._(t`common.minute`).slice(...sliceArgs) : '';
  const minutePlural = style !== 'hide' ? i18n._(t`common.minutes`).slice(...sliceArgs) : '';

  let returnValue = ''
  if (unit === "day") {
    const daySingleOrPlural = days > 1 ? dayPlural : daySingular;
    returnValue = `${
      days + parseFloat((hours / 24 + minutes / (24 * 60)).toPrecision(1))
    } ${daySingleOrPlural}`;
  } else if (unit === "hour") {
    const hourSingleOrPlural = days * 24 + hours > 1 ? hourPlural : hourSingular;
    returnValue = `${days * 24 + hours + parseFloat((minutes / 60).toPrecision(1))} ${hourSingleOrPlural}`;
  } else if (unit === "minute") {
    const minuteSingleOrPlural = days * 24 * 60 + hours * 60 + minutes > 1 ? minutePlural : minuteSingular;
    returnValue = `${days * 24 * 60 + hours * 60 + minutes} ${minuteSingleOrPlural}`;
  }

  return returnValue.trim();
};

export function parseSeconds(inputSeconds: number): ParsedSeconds {
    let seconds = inputSeconds;
    var days = Math.floor(inputSeconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    var hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    var minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
  
    return { days, hours, minutes, seconds };
  }