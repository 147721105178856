import React, { useEffect, useState } from "react";
import { compose, Dispatch } from "redux";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import qs from "qs";

// Elements
import Panel from "@/components/elements/Panel";
import Spinner from "@/components/elements/Spinner";
import {
  CompanyType,
  Configuration,
  CheckoutStateType,
  BookingType,
} from "@/types";
import * as actions from "@/actions";
import { Trans } from "@lingui/macro";
import api from "@/api/services";
import Booking from "@/components/elements/Booking";
import { ApplicationState } from "@/store";
import YouHavePaid from "../partials/YouHavePaid";

interface Props {
  company: CompanyType;
  configuration: Configuration;
  checkout: CheckoutStateType;
  dispatch: Dispatch<any>;
}

export function PaymentSuccess({
  company,
  configuration,
  checkout,
  dispatch,
}: Props) {
  const [booking, setBooking] = useState<BookingType>();

  const { cancellationCode, bookingId, email } = qs.parse(
    document.location.search,
    {
      ignoreQueryPrefix: true,
    }
  );

  useEffect(() => {
    dispatch(actions.fetchCompanyAsync.request());
    if (bookingId && cancellationCode) {
      api
        .getBooking({ Id: bookingId, CancellationCode: cancellationCode })
        .then((response) => {
          setBooking(response.Results[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [bookingId, cancellationCode]);

  if (!company) {
    return (
      <Container fluid>
        <Spinner noGraceTime noColor />
      </Container>
    );
  }

  return (
    <Panel>
      <div>
        <h1 className="text-center display-4">
          <Trans id="successfullyPaid"></Trans>
        </h1>

        <YouHavePaid
          renderPaidElement={() => {
            return (
              <>
                <p className="text-center">
                  <Trans id="youHavePaidTo"></Trans> {company.Name}
                </p>

                <p className="text-center">
                  <Trans id="yourConfirmationSentTo"></Trans> {email}
                </p>
                {!checkout.data && booking && configuration ? (
                  <Booking
                    company={company}
                    booking={booking}
                    // TODO: add cancel booking
                  />
                ) : null}
              </>
            );
          }}
        />
      </div>
    </Panel>
  );
}

export default compose<React.ComponentType>(
  connect(({ company, configuration, checkout }: ApplicationState) => ({
    company: company.data,
    configuration: configuration.data,
    checkout,
  }))
)(PaymentSuccess);
