import React, {  } from "react";
import { Dispatch } from "redux";
import { Container } from "reactstrap";
import qs from "qs";

// Elements
import Panel from "@/components/elements/Panel";
import Spinner from "@/components/elements/Spinner";
import {
  CompanyType,
  Configuration,
  CheckoutStateType,
  Article as ArticleType,
} from "@/types";
import { Trans } from "@lingui/macro";
import { useAppDispatch, useAppSelector } from "@/hooks";
import YouHavePaid from "../partials/YouHavePaid";
import { useGetArticlesQuery } from "@/services/bmApi";
import BoughtArticle from "../partials/BoughtArticle";
import { get } from "lodash";

interface Props {
  company: CompanyType;
  configuration: Configuration;
  checkout: CheckoutStateType;
  dispatch: Dispatch<any>;
}

const ArticlePaymentSuccess: React.FC = () => {
  const { articleId, email } = qs.parse(
    document.location.search,
  );

  const company = useAppSelector(s => s.company.data);
  const configuration = useAppSelector((s) => s.configuration.data);
  
  const { data: articleData, isLoading: articleIsLoading } = useGetArticlesQuery({ Id: Number(articleId), CompanyId: company?.Id });
  const article: ArticleType | undefined = get(articleData, 'Results.0');

  if (!company) {
    return (
      <Container fluid>
        <Spinner noGraceTime noColor />
      </Container>
    );
  }

  return (
    <Panel>
      <div>
        <h1 className="text-center display-4">
          <Trans id="successfullyPaid"></Trans>
        </h1>

        <YouHavePaid
          renderPaidElement={() => (
            <>
              <p className="text-center">
                <Trans id="ArticlePaymentSuccess.successfullyPaidArticle" />{" "}
                {company.Name}
              </p>

              <p className="text-center">
                <Trans id="ArticlePaymentSuccess.confirmationSentTo"></Trans>{" "}
                {email}
              </p>

              {articleIsLoading ? <Spinner noGraceTime noColor /> : null}
              {article ? (
                <BoughtArticle
                  company={company}
                  configuration={configuration}
                  article={article}
                />
              ) : null}
            </>
          )}
        />
      </div>
    </Panel>
  );
}

export default ArticlePaymentSuccess;