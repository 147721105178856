import React from 'react';
import classnames from 'classnames';
import { ModalBody } from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import { Portal } from 'react-portal';
import { CompanyType } from '@/types';
import { Trans } from '@lingui/macro';

interface ModalProps {
    company?: CompanyType;
    open?: boolean;
    onToggle: (e: any) => void;
    node?: HTMLElement
}

const AgreementDialog: React.FC<ModalProps> = ({ company, open = true, onToggle, node }) => {
    return (
      <Portal node={node}>
        <div
          className={classnames("modal fade", {
            show: open,
          })}
          style={{ display: open ? "block" : undefined }}
          onClick={onToggle}
          onKeyDown={onToggle}
        >
          <div className="modal-dialog" data-iframe-height>
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">
                  <Trans id="bookingAgreement.heading"></Trans>
                </h2>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onToggle}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <ModalBody>
                <ReactMarkdown
                  source={company && company.BookingAgreements}
                  linkTarget="_blank"
                />
              </ModalBody>
            </div>
          </div>
        </div>
        {open && (
          <div className="modal-backdrop fade show" onClick={onToggle} onKeyDown={onToggle} />
        )}
      </Portal>
    );
};

export default AgreementDialog;
