import React from "react";
import BEMHelper from "react-bem-helper";

// Elements
import ShowMoreText from "@/components/elements/ShowMoreText";

import { Article as ArticleType, CompanyType, Configuration } from "@/types";

import "./BoughtArticle.css";

interface Props {
  article: ArticleType;
  company: CompanyType;
  configuration: Configuration
}

const c = new BEMHelper({
  name: "Article",
});

const ArticleDetails: React.FC<Props> = ({ article, company, configuration }) => {
  
  return (
      <>
        {!configuration.hideServiceImage && (
          <div {...c("imageContainer")}>
            {article.ImageUrl || company?.LogoType ? (
              <img
                // @ts-ignore
                src={article.ImageUrl || company?.LogoType}
                {...c("image")}
                alt={article.Name}
              />
            ) : (
              <div
                {...c("imagePlaceholder")}
                style={{ backgroundColor: "rgb(240, 240, 235)" }}
              />
            )}
          </div>
        )}
        <div {...c("main")}>
          <div {...c("content")}>
            <h4 {...c("title")}>{article.Name}</h4>
            {article.Description ? (
              <p>
                <ShowMoreText>{article.Description}</ShowMoreText>
              </p>
            ) : null}
          </div>
        </div>
      </>
  );
};

export default ArticleDetails;
