import React from "react";
import BEMHelper from "react-bem-helper";

import ArticleDetails from "./ArticleDetails";
import { Article as ArticleType, CompanyType, Configuration } from "@/types";

import "./BoughtArticle.css";
interface Props {
  article: ArticleType;
  company: CompanyType;
  configuration: Configuration;
}

const c = new BEMHelper({
  name: "Article",
});

const BoughtArticle: React.FC<Props> = ({
  article,
  company,
  configuration,
}) => {
  return company ? (
    <div {...c()}>
      <ArticleDetails
        article={article}
        company={company}
        configuration={configuration}
      />
    </div>
  ) : null;
};

export default BoughtArticle;
