import React, { useRef } from "react";

// Partials
import { ConfigKeys } from "@/misc/constants";
import ServicesPartial from "@/components/partials/Services";
import ArticlesPartial from "../partials/Articles";
import { useAppSelector } from "@/hooks";
import { useGetArticlesQuery } from "@/services/bmApi";
import { RowsGrouped } from "../partials/RowsGrouped";
import { getServicesToShow } from "@/selectors";
import { getTranslate } from "@/misc/common";

export function Services() {
  const ref = useRef(null);
  const localizations = useAppSelector(s => s.localize);
  const translate = getTranslate(localizations);

  const company = useAppSelector((state) => state.company.data);
  const configuration = useAppSelector((state) => state.configuration.data);
  const services = useAppSelector(getServicesToShow);
  const layoutType = configuration[ConfigKeys.LISTING_LAYOUT];
  const { data: allArticles } = useGetArticlesQuery({
    CompanyId: configuration.company,
    // @ts-ignore
    Active: true,
  });

  

  const articlesWithGroup = (allArticles?.Results || []).map((article) => ({
    ...article,
    Group: translate('Articles.GroupName'),
  }));

  if (
    layoutType === ConfigKeys.LISTING_LAYOUT_ROW_BASED_GROUPED &&
    articlesWithGroup &&
    company
  ) {
    return (
      <div ref={ref}>
        <RowsGrouped
          articles={articlesWithGroup}
          services={services}
          company={company}
        />
      </div>
    );
  }

  return (
    <>
      <ServicesPartial />
      <ArticlesPartial />
    </>
  );
}

export default Services;
