import { RootState } from './reducers';
import { Article, Configuration } from './types';

export const getServicesToShow = (state: RootState) => {
    const { services, configuration } = state;
    const { serviceIdsToShow }= configuration.data;
    if (Array.isArray(serviceIdsToShow)) {
        return services.data.filter((service) =>
          serviceIdsToShow
            .map((s) => s.toString())
            .includes(service.Id.toString())
        );
    } else if(typeof serviceIdsToShow === 'string' && serviceIdsToShow) {
        return services.data.filter(s => s.Id.toString() === serviceIdsToShow);
    } else if(typeof serviceIdsToShow === 'number' && serviceIdsToShow) {
        return services.data.filter(s => s.Id.toString() === serviceIdsToShow.toString());
    } else {
        return services.data;
    }
};

export const getArticlesToShow = (articles: Article[], articleIdsToShow: Configuration['articleIdsToShow'] ) => {
    if (Array.isArray(articleIdsToShow)) {
        return articles.filter((article) =>
            articleIdsToShow
            .map((s) => s.toString())
            .includes(article.Id.toString())
        );
    } else if(typeof articleIdsToShow === 'string' && articleIdsToShow) {
        return articles.filter(a => a.Id.toString() === articleIdsToShow);
    } else if(typeof articleIdsToShow === 'number' && articleIdsToShow) {
        return articles.filter(a => a.Id.toString() === articleIdsToShow.toString());
    } else {
        return articles;
    }
};
