import React, { FunctionComponent, useRef, createRef } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Row, Table, Col, Card } from "reactstrap";
import { withRouter, RouteComponentProps } from "react-router";
import { sortBy, uniq, flatten } from "lodash";
import BEMHelper from "react-bem-helper";

import Service from "@/components/partials/Service";
import Panel from "@/components/elements/Panel";
import { groupArray, scrollToElement } from "@/misc/common";
import { ConfigKeys } from "@/misc/constants";
import { CompanyType, Configuration, ServiceType } from "@/types";
import { compose } from "redux";
import { getServicesToShow } from "@/selectors";
import "./Services.css";

import { isBefore } from "@/misc/datetime";
import { ApplicationState } from "@/store";
import ServiceStructuredData from "./ServiceStructuredData";

interface Props extends RouteComponentProps {
  configuration: Configuration;
  services: ServiceType[];
  company: CompanyType;
  dispatch: Dispatch<any>;
  hideSelect?: boolean;
  append?: React.FC<ServiceType>;
}

interface OwnProps {
  hideSelect?: boolean;
  append?: React.FC<ServiceType>;
  services?: ServiceType[];
}

export interface GroupedServicesType {
  [key: string]: Array<ServiceType>;
}

export const c = new BEMHelper({
  name: "Services",
});

export class Services extends React.Component<Props> {
  servicesRef = createRef<any>();

  componentDidMount() {
    const { configuration, services } = this.props;

    scrollToElement({
      element: this.servicesRef.current,
      scrollOffsetY:
        configuration[ConfigKeys.BOOK_LAYOUT] === "service-based"
          ? Number(configuration[ConfigKeys.TOP_OFFSET]) + 72
          : Number(configuration[ConfigKeys.TOP_OFFSET]),
    });

    if (this.props.configuration.selectedService) {
      const service = services.find(
        (service) => service.Id == configuration[ConfigKeys.SELECTED_SERVICE]
      );

      if (service) {
        this.props.history.push(`/services/${service.Id}/times`);
        this.props.dispatch({
          type: "SELECT_SERVICE",
          payload: service.Id
        });
      }
    }
  }

  render() {
    const { configuration, services, company } = this.props;
    const layoutType = configuration[ConfigKeys.LISTING_LAYOUT];
    const targetOrigin = configuration?.targetOrigin
      ? configuration?.targetOrigin
      : configuration?._targetOrigin;

    if (layoutType === ConfigKeys.LISTING_LAYOUT_ROWS_COMPACT) {
      return <div ref={this.servicesRef}>
        {this.renderServicesRows(services, true)}
      </div>;
    }
    if (layoutType === ConfigKeys.LISTING_LAYOUT_ROWS) {
      return (
        <div ref={this.servicesRef}>
          {this.renderServicesRows(services)}
        </div>
      );
    }
    if (layoutType === ConfigKeys.LISTING_LAYOUT_COLUMNS) {
      return (
        <div ref={this.servicesRef}>
          {this.renderServicesColumns(services)}
        </div>
      )
    }

    console.error("Unknown services layout detected: " + layoutType);

    return false;
  }

  renderServicesColumns = (services: ServiceType[]) => {
    const groupedServices: GroupedServicesType = groupArray(services, "Group");
    const company = this.props.company;

    return (
      <div {...c(undefined, { column: true })}>
        {Object.keys(groupedServices)
          .sort((a, b) => a.localeCompare(b))
          .map((groupName) => (
            <div key={groupName}>
              <h4 {...c("title")}>{groupName}</h4>
              <Row className={'mb-2'} >
                {groupedServices[groupName]
                  .sort((a, b) => a.SortOrder - b.SortOrder)
                  .map((service) => (
                    <Col xs={12} sm={6} md={4} lg={4} key={service.Id}>
                      <Service
                        service={service}
                        hideSelect={this.props.hideSelect}
                        append={this.props.append}
                      />
                      <ServiceStructuredData service={service} company={company} />
                    </Col>
                  ))}
              </Row>
            </div>
          ))}
      </div>
    );
  };

  renderServicesRows = (services: ServiceType[], compact = false) => {
    const groupedServices: GroupedServicesType = groupArray(services, "Group");
    const sortedGroupsBySortOrder = uniq(sortBy(services, 'SortOrder', 'Group').map(s => s.Group || ""));
    const company = this.props.company;
    
    if (compact) {
      return (
        <Panel>
          <Table className="responsive-table" borderless>
            <tbody>
              {sortedGroupsBySortOrder
                .map((groupName) => (
                  <React.Fragment key={groupName}>
                    <tr>
                      <td colSpan={5}>
                        <h4>{groupName}</h4>
                      </td>
                    </tr>
                    {groupedServices[groupName]
                      .sort((a, b) => a.SortOrder - b.SortOrder)
                      .map((service) => this.renderServiceRow(service, true))}
                  </React.Fragment>
                ))}
            </tbody>
          </Table>
        </Panel>
      );
    }

    return (
      <div>
        {sortedGroupsBySortOrder
          .map((groupName) =>
            groupedServices[groupName]
              .sort((a, b) => a.SortOrder - b.SortOrder)
              .map((service) => (
                <div key={service.Id}>{this.renderServiceRow(service)} </div>
              ))
          )}
      </div>
    );
  };

  renderServiceRow = (service: ServiceType, compact = false) => {
    const company = this.props.company;
    return (
      <>
        <Service
          key={service.Id}
          service={service}
          hideSelect={this.props.hideSelect}
          append={this.props.append}
          compact={compact}
        />
        <ServiceStructuredData service={service} company={company} />
      </>
    );
  };
}

const mapStateToProps = (state: ApplicationState) => ({
  configuration: state.configuration.data,
  services: getServicesToShow(state),
  company: state.company.data,
});

export default compose<React.ComponentType<OwnProps>>(
  withRouter,
  connect(mapStateToProps)
)(Services);
