import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { definitions, operations } from '@/apitypes';
import { ApplicationState } from '@/store';
import keycloak from '@/keycloak';
import readConfigurationProperty from "../misc/readConfigurationProperty";
import { Article, GetArticlesResponse, GetRebateCodesQuery, RebateCodeResponse } from '@/types';
import { getXlanguageCode } from '@/misc/common';
export type User = definitions['CurrentUserQueryResponse'];


export const apiService = createApi({
    reducerPath: 'bmApi',
    tagTypes: ['rebateCodes'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        // @ts-ignore
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_API_KEY,
            'x-language': getXlanguageCode(),
        },
        prepareHeaders(headers, api) {
            const bearerToken = keycloak?.token
            const sessionId = keycloak?.sessionId;

            if (bearerToken && sessionId) {
                headers.set('Authorization', `Bearer ${bearerToken}`);
                headers.set('X-Ss-Id', `${sessionId}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => {
        return {
          getUsers: builder.query<
            any,
            Partial<{ IncludeCompanyCustomers: boolean }>
          >({
            // transformResponse: (res: Paging<Service>) => res.Results.map(s => s.Prices).flat(),
            query: (params = {}) => ({
              url: "/users",
              method: "get",
              params,
            }),
          }),
          getArticles: builder.query<
            GetArticlesResponse,
            Partial<definitions["GetArticles"]>
          >({
            query: (params) => ({
              url: "/articles",
              method: "get",
              params,
            }),
          }),
          getRebateCodes: builder.query<RebateCodeResponse, GetRebateCodesQuery>({
            query: (params) => ({
                url: "/rebatecodes",
                method: "get",
                params: {
                  ...params
                },
            }),
            providesTags: ['rebateCodes']
        })
        };
    },
});

export const {
    useGetUsersQuery,
    useGetArticlesQuery,
    useGetRebateCodesQuery,
    useLazyGetRebateCodesQuery
} = apiService;

export default apiService;
