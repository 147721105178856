import React, { useState } from "react";
import { ConnectedProps } from "react-redux";
import BEMHelper from "react-bem-helper";

// Elements
import { Button } from "bokamera-embedded-ui";
import ShowMoreText from "@/components/elements/ShowMoreText";

import { Article as ArticleType, Currency, ServiceType } from "@/types";

import "./Service.css";
import { Trans } from "@lingui/macro";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { selectArticle } from "@/reducers/article";
import ArticleDetails from "./ArticleDetails";

interface OwnProps {
  article: ArticleType;
  hideSelect?: boolean;
  append?: React.FC<ServiceType>;
  compact?: boolean;
}

const c = new BEMHelper({
  name: "Service",
});

const Article: React.FC<OwnProps> = ({ article, hideSelect = false }) => {
  const configuration = useAppSelector((s) => s.configuration.data);
  const company = useAppSelector((s) => s.company.data);
  const dispatch = useAppDispatch();

  return company ? (
    <div {...c()} data-testid={`article-id-${article.Id}`}>
      <ArticleDetails
        article={article}
        company={company}
        configuration={configuration}
      />
      <div {...c("divider")} />
      <div {...c("actionBox")}>
        <div {...c("priceContainer")} data-testid="service-prices">
          <div {...c("price")}>
            {/* @ts-ignore */}
            <span {...c("priceValue")}>
              {article.Price}
              {article.PriceSign}
            </span>
          </div>
        </div>
        {!hideSelect && (
          <Button
            {...c("select")}
            primary
            outline
            onClick={() => dispatch(selectArticle({ article }))}
          >
            <Trans id="Article.buy"></Trans>
          </Button>
        )}
      </div>
    </div>
  ) : null;
};

export default Article;
